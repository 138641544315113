body,
#root {
  min-height: 100%;
}

iframe {
  pointer-events: none;
}

legend span {
  z-index: initial !important;
}

.menu-position-top.menu-w
  ul.main-menu
  > li.selected
  .sub-menu-w
  .sub-menu-i
  ul.sub-menu {
  overflow-x: hidden;
}

ul.main-menu > li .sub-menu-w {
  transition: none !important;
}

.auth-container {
  min-height: 100vh;

  & .auth-subcontainer {
    min-height: 100vh;
    padding: 5%;

    & .logo {
      max-width: 400px;
      margin-bottom: 20px;
    }

    &.left {
      text-align: center;

      & h2 {
        text-transform: uppercase;
        font-size: 20px;
        color: #c03239;
      }

      & p {
        text-align: left;
        max-width: 400px;
        margin: 0 auto;
        background: #eaeef5;
        padding: 10px 20px;
        border-radius: 4px;
        color: #595959;
      }
    }

    &.right {
      background: #ffffff;

      & .form-check-inline {
        margin-left: 10px;
      }
    }
  }
}

.loading-wrapper {
  text-align: center;
  align-items: center;
  padding-top: 20%;
  font-size: 35px;
  text-transform: uppercase;
  letter-spacing: 5px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

// Add styles here you want to override
.upload-dropzone {
  height: 150px;
  border: 1px dashed #dddddd;
  background: #f9f9f9;
  display: flex;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background: #e9e9e9;
    border: 1px solid $top-bar-bright-bg;
  }

  &.hidden {
    display: none;
  }

  &.has-preview {
    border-top: 1px solid #f4f4f4;
  }

  h3 {
    font-size: 18px;
    font-weight: bold;
  }

  p {
    margin: 0 auto;
    text-align: center;
  }
}

.form-upload {
  margin-bottom: 10px;

  &.single .preview {
    position: absolute;
    height: 15vh;
    margin: 0 auto;
    cursor: pointer;
    border: 1px dashed #dddddd;

    &:hover {
      opacity: 0.8;
      border: 1px solid $top-bar-bright-bg;
    }
  }

  &.multiple .preview-container {
    border: 1px dashed #dfdfdf;
    background: #f9f9f9;
    border-bottom: none;
    padding: 2px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    cursor: pointer;
  }

  & .preview {
    height: 100px;
    transition: transform 0.2s ease-in-out;
  }

  & .preview:hover {
    transform: scale(0.9, 0.9);
  }

  &.has-error .upload-dropzone {
    border: 2px solid $danger;
  }

  & .error-message {
    color: $danger;
    font-weight: bold;
    font-size: 0.8rem;
    padding: 0 10px;
    text-align: center;
  }
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: -12px;
}

.form-error {
  color: #fff;
  background-color: $danger;
  padding: 6px 25px;
  border-radius: 4px;
}

.actions-right {
  & .delete-confirm {
    margin: 0 10px;
  }
}

.input-search-w input {
  border: none;
}

.expanded-img {
  position: fixed;
  margin: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 80%;
  max-height: 80%;
  z-index: 999;
  border: 2px solid #293145;
  box-shadow: 1px 2px 2px #00000044;
}

.expanded-backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #00000066;
  z-index: 998;
  left: 0;
  top: 0;
}

.fares {
  & table {
    & thead th,
    & tbody th {
      background: #1b55e2;
      color: #fff;
      font-weight: bold;
      user-select: none;
      cursor: pointer;

      &:hover {
        background: #293146;
        color: #ffb100;
        border-color: #ffb100;
      }
    }

    & thead th {
      text-align: center;
      position: sticky;
      top: 0px;
    }

    & tbody th {
      text-align: right;
    }

    & tbody td.fare {
      cursor: pointer;

      &:hover {
        border: 2px solid #293145;
      }

      &.selected {
        border: 3px solid #000000;
      }
    }
  }

  & .actions {
    position: sticky;
    top: 40px;
    background: #293145;
    color: #fff;
    height: 41px;
    padding: 10px;
    text-align: right;
    font-weight: bold;

    & .btn {
      margin-left: 10px;
    }
  }
}

.ae-main-menu {
  & .header {
    background: #293145;

    & a span {
      color: #ffffff !important;
      font-weight: bold !important;
    }
  }

  & .loading {
    text-align: center;
  }

  & li.active {
    background: #1b55e2;

    & a span,
    a i {
      color: #ffffff;
    }
  }
}

.ae-list-w .ae-no-record {
  height: auto;
  background: #eceef2;
  padding: 10px 20px;
  font-weight: bold;
  border-bottom: 1px solid #e5e5e5;
}

.balance-table {
  margin-top: 1px;
}

.balance-table table td {
  text-align: center;
}

.balance-table table th {
  width: 100%;
  text-align: center;
}

.balance-table table th a {
  color: #ffffff;
}

.balance-table table thead th {
  background: #1b55e2;
  color: #fff;
  font-weight: bold;
}

//////////////////////////////////////////

// GRID Layout

///////////////////////////////////////////////

.gallery {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(7, 5vw);
  grid-gap: 1.5rem;
  padding: 0 2rem;
}

.gallery__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.gallery__item--1 {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 3;

  /** Alternative Syntax **/
  /* grid-column: 1 / span 2;  */
  /* grid-row: 1 / span 2; */
}

.gallery__items--1 {
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 4;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.gallery__item--2 {
  grid-column-start: 3;
  grid-column-end: 9;
  grid-row-start: 1;
  grid-row-end: 7;

  /** Alternative Syntax **/
  /* grid-column: 3 / span 2;  */
  /* grid-row: 1 / span 2; */
}

.gallery__items--2 {
  grid-column-start: 4;
  grid-column-end: 9;
  grid-row-start: 1;
  grid-row-end: 7;
  background-color: #ffffff;
}

.gallery__item--3 {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 3;
  grid-row-end: 5;

  /** Alternative Syntax **/
  /* grid-column: 5 / span 4;
  grid-row: 1 / span 5; */
}

.gallery__items--3 {
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 4;
  grid-row-end: 7;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  text-align: center;
}

.gallery__item--4 {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 5;
  grid-row-end: 7;

  /** Alternative Syntax **/
  /* grid-column: 1 / span 4;  */
  /* grid-row: 3 / span 3; */
}

input.register-input {
  width: 400px;
  height: 38px;
  padding-left: 15px;
  padding-right: 4% !important;
  color: #555 !important;
  border: 1px solid #cecece !important;
  background-color: #fff !important;
  font-size: 16px !important;
  border-radius: 5px 5px 5px 5px !important;
  position: absolute;
  inset: 10px 35% 0;
  z-index: 1000;
}

.textBox {
  border: rgba(0, 0, 0, 0.2);
  border-width: 1px;
  border-style: solid;
  font-size: 15px;
  padding: 5px;
  border-radius: 5px;
  width: 230px;
  margin-top: 5px;
}

.estimate {
  max-height: 0px;
  overflow: hidden;
  display: flex;
  justify-content: space-evenly;
  margin-top: 2px;
  box-shadow: 0 20px 42px rgba(0, 0, 0, 0.25);
  background-color: #000000;
  border-radius: 8px 8px;
  transition: max-height 400ms ease-out;
}

.estimate.show {
  max-height: 100px;
  height: auto;
  padding: 10px 10px 10px 10px;
}
.estimate-main-box {
  max-height: 100px;
  height: auto;
  padding: 10px 10px 100px 10px;
}

.estimate-box h4 {
  font-size: 14px;
  margin: 0;
  padding: 0;
  color: #afccff;
  text-transform: uppercase;
}

.estimate-box p {
  font-weight: bold;
  color: #ffffff;
}

.estimate .hero p {
  font-size: 40px;
  margin-top: 9px;
  color: #7fff00;
}

.hovering:hover {
  background-color: #dddddd;
}

.btns {
  background-color: #1b55e2;
  border: none;
  border-radius: 8px;
  margin-left: 3px;
  color: white;
  padding: 8px 12px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 5px;
  margin-bottom: 5px;
}

.btns:hover {
  background-color: RoyalBlue;
}

.PhoneInputInput {
  display: block;
  width: 100%;
  height: calc(calc(1.5em + 0.75rem) + 4px);
  padding: 0.375rem 0.75rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 2px solid #dde2ec;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.PhoneInputInput:focus {
  color: #495057;
  background-color: #fff;
  border-color: #047bf8;
  outline: 0;
  box-shadow: none;
}

.activeLink {
  color: #fff;
  background-color: #37717d;
}

.map-ui-wrapper-left {
  position: absolute;
  padding: 10px;
}
.map-ui-wrapper-right {
  position: absolute;
  padding: 10px;
  flex-direction: row;
  right: 0px;
}

.map-dispatch-alert-wrapper.alerting {
  animation: map-dispatch-alert-glow 0.5s infinite alternate;
}

.map-dispatch-wrapper input[type="phone"] {
  padding: 5px 10px;
  border-radius: 2px;
  border: 2px solid #2c5b65;
  background: #2c5b65dd;
  color: #ffffff;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.map-dispatch-wrapper input[type="phone"]::placeholder {
  color: #ffffff;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.map-dispatch-alert {
  border: 2px solid #2c5b65;
  border-top: none;
}
.map-dispatch-alert-title {
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  background: #ee0000;
  color: white;
  padding: 5px 10px;
  margin: 0;
}
.map-dispatch-alert-actions {
  background: white;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 10px 5px;
  gap: 10px;
}
.map-dispatch-alert-actions button {
  border: none;
  color: white;
  text-transform: uppercase;
  padding: 5px 15px;
  border-radius: 2px;
  font-weight: bold;
}
.map-dispatch-alert-actions button.alert-accept {
  background: green;
}
.map-dispatch-alert-actions button.alert-reject {
  background: #ee0000;
}

.map-dispatch-form {
  background: #fff;
  padding: 10px;
  border: 1px solid #487079;
  border-radius: 4px;
  margin-top: 5px;
}

.map-dispatch-form-field {
  display: flex;
  justify-content: space-between;
  margin-top: 2px;
}

.map-dispatch-form-field input {
  border: 1px solid #aaa;
  border-radius: 4px;
  padding: 4px 8px;
}

.map-dispatch-book-wrapper {
  text-align: center;
  display: flex;
  gap: 5px;
  justify-content: center;
}

.map-dispatch-book {
  font-size: 14px;
  text-transform: uppercase;
  padding: 5px 40px;
  margin-top: 10px;
  margin-bottom: 10px;
  background: #000000;
  color: white;
  letter-spacing: 2px;
  border: none;
  border-radius: 4px;
  font-weight: bold;
}

.map-dispatch-address-list h3 {
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 0px;
  background: #487079;
  color: white;
  padding: 5px 10px;
}

.map-dispatch-address-list ul {
  padding: 5px;
}

.map-dispatch-address-list ul li {
  list-style-type: none;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
}

.map-dispatch-address-list ul li div {
  max-width: 20vw;
}

.map-dispatch-address-list .map-dispatch-address-empty {
  font-style: italic;
  padding: 5px;
}

.map-dispatch-address-pickup,
.map-dispatch-address-dropoff {
  background: #2c5a64;
  color: white;
  padding: 4px 8px;
  border: none;
  border-radius: 4px;
  margin-right: 2px;
}

.map-sections {
  max-height: calc(100vh - 120px);
  overflow-y: auto;
}

.map-section {
  width: 300px;
}

.map-section-title {
  background: #487079;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 10px 15px;
  margin-bottom: 0;
  letter-spacing: 1.5px;
  cursor: pointer;
  user-select: none;
}

.map-section-title a {
  color: #ffffff;
  cursor: pointer;
  padding: 2px;
}

.map-section-title a:hover {
  color: #487079;
  background-color: #ffffff;
  border-radius: 4px;
  text-decoration: none;
}

.map-section-title .badge {
  font-size: 0.81rem;
  background: #2b4247;
  color: white;
  text-align: center;
  border-radius: 2px;
  font-weight: bold;
}

.map-section-subtitle {
  background: #2b4247;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 4px 7px;
  margin-bottom: 0;
  letter-spacing: 1.5px;
}

.map-section-body {
  border: 1px solid #49707a;
  background: #ffffff;
  overflow-x: hidden;
  max-height: 57vh;
  transition: all 0.2s ease-out;
}

.map-section-body.hidden {
  max-height: 0;
}

.map-section-item {
  display: flex;
  gap: 10px;
  padding: 10px;
  cursor: pointer;
  justify-content: space-between;
}

.selected-driver {
  text-decoration: none;
  background: #49707a;
  color: white;
}

.map-section-item:hover {
  text-decoration: none;
  background: #49707a;
  color: white;
}

.map-section-item-timestamp {
  font-size: 12px;
  width: 100px;
  text-align: right;
  font-style: italic;
}

.select-driver-filter {
  z-index: 1;
}

.map-section-filters {
  background: #f2f2f2;
  display: flex;
  flex-direction: column;
  border: 1px solid #49707a;
  padding: 10px;
  gap: 5px;
  overflow: hidden;
  max-height: 1000px;
  transition: all 0.1s ease-out;
}

.map-section-filters.hidden {
  max-height: 0;
  padding: 0;
}

.map-section-filter {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  align-items: baseline;
}

.map-section-filter label {
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  width: 82px;
}

.map-section-filter input,
.map-section-filter select {
  width: 100%;
  max-width: 240px;
  border: 1px solid #999999;
  padding: 4px 8px;
  border-radius: 2px;
}

.map-section-filters .btn-primary {
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-weight: bold;
}

.map-section-empty {
  padding: 10px;
  font-weight: bold;
  text-align: center;
  background: #dfdfdf;
  text-transform: uppercase;
}

.map-section .loading {
  display: block;
  float: left;
  width: 20px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 5px solid #c5cda6;
  animation: l20-1 0.8s infinite linear alternate, l20-2 1.6s infinite linear;
  margin-right: 5px;
}

.map-details {
  background: #ffffff;
  border: 1px solid #49707a;
  padding: 10px;
  margin-right: 5px;
  width: 300px;
  height: fit-content;
}

.map-details h4 {
  background: #487079;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 10px 15px;
  margin-bottom: 0;
  letter-spacing: 1.5px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

.map-details h4 .os-icon {
  color: white;
  font-weight: bold;
  font-size: 10px;
}

.map-details table {
  width: 100%;
}

.map-details table tr td:first-child {
  text-align: right;
  font-weight: bold;
  padding-right: 10px;
}

@keyframes l20-1 {
  0% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
  }
  12.5% {
    clip-path: polygon(
      50% 50%,
      0 0,
      50% 0%,
      100% 0%,
      100% 0%,
      100% 0%,
      100% 0%
    );
  }
  25% {
    clip-path: polygon(
      50% 50%,
      0 0,
      50% 0%,
      100% 0%,
      100% 100%,
      100% 100%,
      100% 100%
    );
  }
  50% {
    clip-path: polygon(
      50% 50%,
      0 0,
      50% 0%,
      100% 0%,
      100% 100%,
      50% 100%,
      0% 100%
    );
  }
  62.5% {
    clip-path: polygon(
      50% 50%,
      100% 0,
      100% 0%,
      100% 0%,
      100% 100%,
      50% 100%,
      0% 100%
    );
  }
  75% {
    clip-path: polygon(
      50% 50%,
      100% 100%,
      100% 100%,
      100% 100%,
      100% 100%,
      50% 100%,
      0% 100%
    );
  }
  100% {
    clip-path: polygon(
      50% 50%,
      50% 100%,
      50% 100%,
      50% 100%,
      50% 100%,
      50% 100%,
      0% 100%
    );
  }
}
@keyframes l20-2 {
  0% {
    transform: scaleY(1) rotate(0deg);
  }
  49.99% {
    transform: scaleY(1) rotate(135deg);
  }
  50% {
    transform: scaleY(-1) rotate(0deg);
  }
  100% {
    transform: scaleY(-1) rotate(-135deg);
  }
}

.map-dot {
  position: relative;
}

.circle {
  width: 15px;
  height: 15px;
  background-color: #62bd19;
  border-radius: 50%;
  position: absolute;
  bottom: 19px;
  left: -7px;
}

.ringring {
  border: 3px solid #62bd19;
  border-radius: 30px;
  height: 25px;
  width: 25px;
  position: absolute;
  left: -12px;
  bottom: 14px;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
}

.map-dot.driver.driver-status-online .circle {
  background-color: #62bd19;
}
.map-dot.driver.driver-status-online .ringring {
  border-color: #62bd19;
}

.map-dot.driver.driver-status-offline .circle {
  background-color: #8c8c8c;
}
.map-dot.driver.driver-status-offline .ringring {
  border-color: #8c8c8c;
  display: none;
}

.map-dot.driver.driver-status-on-trip .circle {
  background-color: #00a8ff;
}
.map-dot.driver.driver-status-on-trip .ringring {
  border-color: #00a8ff;
}

.map-marker h4 {
  text-transform: uppercase;
  font-size: 12px;
  width: max-content;
  position: relative;
  bottom: 30px;
  left: -56px;
  border: 1px solid #888888;
  -webkit-text-stroke-color: #ffffff;
  padding: 2px 4px;
  background: black;
  color: white;
  text-align: center;
  cursor: pointer;
}

.map-marker:hover h4 {
  background-color: #555555;
}

.map-marker table {
  z-index: 1;
  position: relative;
  left: -87px;
  top: -12px;
  background: black;
  color: white;
  border: 5px solid #2c5b65;
}

.map-marker table td {
  padding-top: 7px;
  padding-bottom: 7px;
}

@keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

@keyframes map-dispatch-alert-glow {
  from {
    box-shadow: 0 0 10px -10px #ee0000;
  }
  to {
    box-shadow: 0 0 10px 10px #ee0000;
  }
}
